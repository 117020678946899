
<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {ref, computed} from "vue";
import {useStore} from "vuex";
// import SvgIcon from '@/components/SvgIcon';

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  setup(){
    const store        = useStore();
    const isAdmin      = computed( () => store.state.token_type == 1);
    const search       = ref('');
    const country      = store.state.countries.find(d => d.id == store.state.token_country);
    const publications = computed( () => {
      return isAdmin.value ? store.getters.getPublications() : store.getters.getPublications(store.state.token_country)
    });
    const deletePublication = id => store.dispatch("deletePublication", id);

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      publications,
      search,
      getIcon : store.getters.getIcon,
      country,
      deletePublication,
      isAdmin
    }
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components : {
    // SvgIcon
  }
}
</script>
<template>
  <section>
    <h1 class="mb-4 pb-4">Publicaciones</h1>
    <p>
      <router-link :to="{name : 'AddPublicationAdmin'}">crear publicación</router-link>
    </p>
    <div v-if="publications.length">
      <!-- <div class="row mb-4 pb-4">
        <div class="col-12 mb-4 pb-4">
          <input
            v-model="search"
            class="od_search"
            placeholder="Buscar publicación" />
        </div>
      </div> -->

      <div v-for="(pub, i) in publications" :key="`pub-${i}`">
        <div class="row">
          <div class="col-12 mt-4 pt-4">
            <h2>{{ pub.title }}</h2>
            <h6>
              Autor: <strong>{{ pub.author }}</strong>
            </h6>
          </div>
        </div>
        <div class="im_box_row back gray mt-2 mb-4">
          <div class="row">
            <div class="col-sm-6">
              <h6>
                <strong>{{ pub.category }}</strong>
              </h6>
            </div>
            <div class="col-sm-6">
              <h6 class="text-right">
                Fecha: <strong>{{ pub.date }}</strong>
              </h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p><img :src="'/covers/' + pub.cover" /></p>
          </div>
          <div class="col-9">
            <h5><strong>Descripción</strong></h5>
            <p>{{ pub.description }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 offset-sm-9">
            <p class="text-right pb-0">
              <router-link :to="{name : 'UpdatePublicationAdmin', params : {id : pub.id}}">editar</router-link>
              <button @click.prevent="deletePublication(pub.id)" >eliminar</button>
              <!-- <button></button> -->
            </p>
          </div>
          <div class="col-12">
            <div class="ip_separator bottom"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12">
          <div class="ip_separator bottom"></div>
          <h3 v-if="isAdmin">Aún no se agregan publicaciones</h3>
          <h3 v-else>Aún no se agregan publicaciones en {{ country.name }}.</h3>
        </div>
      </div>
    </div>
  </section>
</template>